<template>
    <b-container
        class="layout-auth vh-100"
        fluid="xl"
    >
        <div class="d-flex flex-column h-100">
            <monoa-circle
                class="position-absolute d-none d-sm-block"
                variant="primary"
                :size="355"
                :stroke-width="5"
                style="top: -140px; left: -180px;"
            />

            <div class="d-flex flex-grow-1 align-items-center">
                <router-view />
            </div>

            <div class="d-flex justify-content-between py-5 px-3 px-xl-0">
                <b-link class="text-muted">
                    {{ $t('auth.links.terms') }}
                </b-link>

                <span class="text-muted">
                    All rights reserved MoNoA 2020
                </span>

                <span class="text-muted">
                    Built by Wizkids
                </span>
            </div>
        </div>
    </b-container>
</template>

<script>
import MonoaCircle from '@/public/components/common/MonoaCircle';
export default {
    name: 'Auth',
    components: {MonoaCircle},
};
</script>
