<template>
    <svg
        :width="`${size}px`"
        :height="`${size}px`"
        class="monoa-circle"
        viewBox="0 0 246 246"
        xmlns="http://www.w3.org/2000/svg"
        v-bind="$attrs"
    >
        <path
            :class="`stroke-${variant}`"
            d="M123,238 C186.512746,238 238,186.512746 238,123 C238,59.4872538 186.512746,8 123,8 C59.4872538,8 8,59.4872538 8,123 C8,186.512746 59.4872538,238 123,238 Z"
            :stroke-width="strokeWidth"
            fill="none"
            :stroke-dasharray="strokeDashArray.join(', ')"
            stroke-linecap="round"
            stroke-linejoin="round"
            vector-effect="non-scaling-stroke"
        />
    </svg>
</template>

<script>
export default {
    name: 'MonoaCircle',
    props: {
        size: {
            type: Number,
            default: 246,
        },
        strokeWidth: {
            type: Number,
            default: 10,
        },
        strokeDashArray: {
            type: Array,
            default: () => [26.3, 37.4],
        },
        variant: {
            type: String,
            default: 'primary',
        },
    },
    data: () => ({
        viewBoxSize: 246,
    }),
};
</script>
